import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Star Buds',
    useCases: 'BRANDING, WEB DESIGN, APP DEVELOPMENT',
    description: `Mobile application for the cannabis industry community.`,
    comment: 'usecase coming soon',
  },
  caseId: 'star-buds',
  contents: ['image0.jpg'],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
